import React from "react";
import { Link, Location } from "@reach/router";

const Nav = () => (
  <nav className="site-nav">
    <Link to="/">
      <img
        src="/icon.svg"
        alt="thought assassin logo - link to home"
        height="50px"
      />
    </Link>
    <Location>
      {({ location }) => (
        <ul>
          <li className={location.pathname === "/art" ? "active" : ""}>
            <Link to="art">Art</Link>
          </li>
          <li className={location.pathname === "/web" ? "active" : ""}>
            <Link to="web">Web</Link>
          </li>
        </ul>
      )}
    </Location>
  </nav>
);

export default Nav;
