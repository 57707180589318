import React, { useEffect, useState, useCallback } from "react";
import { TransitionGroup, Transition } from "react-transition-group";
import "../../components/Cards/Card.css";

const Art = () => {
  const [art, setArt] = useState([]);
  const getArt = useCallback(srcs => setArt(srcs), [setArt]);

  useEffect(() => {
    fetch("MockArt.json")
      .then(response => response.json())
      .then(getArt);
  }, [getArt]);

  return (
    <section className="art">
      <div className="content">
        <TransitionGroup
          enter={true}
          exit={false}
          appear={true}
          className="transition-group"
        >
          {art.map((art, i) => (
            <Transition key={i} timeout={i * 30}>
              {status => (
                <div className={`card card-${status}`}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/wallpapers/${art.src}`}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/thumbnails/${art.src}`}
                      alt={art.title}
                    />
                  </a>
                </div>
              )}
            </Transition>
          ))}
        </TransitionGroup>
      </div>
    </section>
  );
};

export default Art;
