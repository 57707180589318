import React from "react";

const Header = () => (
  <header className="site-header">
    <h1>
      <img src="/david_krause.jpg" alt="David Krause" />
      Thought Assassin
    </h1>
    <div className="description">
      <p>
        My name is David Krause. Select from the menu above to see examples of
        my art or web work. If you want to contact me &mdash;{" "}
        <a href="mailto:info@thoughtassassin.com">send an email</a>.
      </p>
    </div>
  </header>
);

export default Header;
