import React from "react";
import Nav from "./Nav";
import Header from "./Header";
import { Router } from "@reach/router";
import Web from "./Web";
import Art from "./pages/Art/Art";

import "./App.css";

const App = () => (
  <div className="App">
    <Nav />
    <Header />
    <Router primary={false}>
      <Art path="art" />
      <Web path="web" />
    </Router>
  </div>
);

export default App;
